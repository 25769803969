<template>
<span v-tooltip="`${priority.level} priority`">
    <icon 
        :icon="currentPriority" 
        type="fas"
        class="priority-indicator"
        :class="{
            'has-text-danger': priority.level === 'high',
            'has-text-warning': priority.level === 'medium',
        }"
    />    
</span>    
</template>
<script>
export default {
    
    props: {
        priority: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        currentPriority() {
            switch(this.priority.level) {
                case 'low': return 'temperature-empty'
                case 'medium': return 'temperature-half'
                case 'high': return 'temperature-full'
            }
            return 'temperature-empty'
        }
    }

}
</script>